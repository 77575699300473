body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f1941 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.MuiInputLabel-formControl {
  color: #0f1941 !important;
}

.birthday-field input::-webkit-date-and-time-value {
  text-align: left !important;
}

.MuiFormHelperText-root {
  color: #d32f2f !important;
}
